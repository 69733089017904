.Guess{
    background-color: #F6EABE;
    margin-top:1.1%;
    flex:row;
    width: 65%;
    height: 6vh;
    border-radius: 40px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .Flag{
    float:left;
    height: 100%;
    width: 6%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-left: 1%;
  }

  .GuessName{
    float:left;
    background-color: #789395;
    margin-left: 1%;
    margin-top:1vh;
    margin-bottom:1vh;
    height: 70%;
    width: 50%;
    border-radius: 25px;
    text-align: center;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    font-family: "Patrick Hand";
  }
  
  .GuessDistance{
    float:left;
    background-color: #789395;
    margin-left:1%;
    margin-top:1vh;
    width: 19%;
    height: 70%;
    text-align: center;
    border-radius: 25px;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  }
  
  .GuessDirection{
    float:left;
    background-color: #789395;
    margin-left: 1%;
    margin-top:1vh;
    width: 12%;
    height: 70%;
    text-align: center;
    border-radius: 25px;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  }
  
  .GuessPercentage{
    float:left;
    background-color: #789395;
    width: 7%;
    height: 70%;
    margin-right: 1%;
    margin-left: 1%;
    border-radius: 100px;
    margin-top:1vh;
    text-align: center;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  }

  @media (orientation: portrait) {
    .Guess{
     width: 90%;
    }
    
    .Flag{
      width: 10%;
      margin-left: 3%;
    }
  
    .GuessName{
      font-size: 100%;
      width: 40%;
    }
    
    .GuessDistance{
      font-size: 100%;
      width: 20%;
    }
    
    .GuessDirection{
      font-size: 100%;
      width: 20%;
    }
    
    .GuessPercentage{
      font-size: 100%;
      width: 10%;
    }
  }
  